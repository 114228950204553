<template>
  <div class="order-list-wrapper" v-loading="false" :element-loading-text="'企业当前正在执行【导入订单并发货】...' + progress"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.4)">
    <en-table-layout :tableData="tableData" :loading="loading" :span-method="orderSpanMethod"
      @selection-change="orderListSelectionChange">
      <template slot="header">
        <el-tabs v-model="params.order_status" type="card" @tab-click="advancedSearchEvent">
          <el-tab-pane v-for="(item, index) in MixinOrderStatusOptions[0]" :key="item.value"
            :label="item.label + templateOrdernum(item.label)" :name="item.value" :disabled="loading"></el-tab-pane>
        </el-tabs>
      </template>
      <template slot="toolbar">
        <el-form-item v-show="params.order_status === `ALL`" label="物流状态" class="col-auto">
          <el-select style="width: 100px" v-model="advancedForm.logistics_status" size="small" placeholder="请选择"
            clearable>
            <el-option label="全部" :value="undefined" />
            <el-option label="未发货" :value="0" />
            <el-option label="拣货中" :value="1" />
            <el-option label="已发货" :value="2" />
            <el-option label="已收货" :value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="下单日期" class="col-auto">
          <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-form-item class="col-auto">
          <el-input style="width: 280px" v-model.trim="advancedForm.keywords" placeholder="请输入关键词" clearable>
            <el-select style="width: 130px;" v-model="search_type" slot="prepend">
              <el-option label="订单编号" value="order_sn"></el-option>
              <el-option label="下单账号" value="mobile_account"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="物流单号" value="ship_no"></el-option>
              <el-option label="收货人手机号" value="ship_mobile"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <div class="col-auto" style="margin-right: 10px;margin-bottom:8px">
          <el-button @click="advancedSearchEvent();" size="small" type="primary">搜索</el-button>
          <el-button @click="submitImport();" size="small" type="primary">导出订单</el-button>
          <el-button @click="exportOrderRecords" size="small" type="primary">导出记录</el-button>
          <el-button v-if="['ALL','CONFIRM','PAID_OFF'].includes(params.order_status)" @click="handleDeleteGoods" size="small" type="primary">批量取消</el-button>
        </div>
        <div class="col"></div>
      </template>
      <template slot="table-columns">
        <el-table-column type="selection" />

        <el-table-column label="订单编号" prop="sn" width="180" fixed="left">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">{{ row.trade_sn }}</template>
          </template>
        </el-table-column>

        <el-table-column label="订单总额" width="100" fixed="left">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">
              <span>
                {{ ([1, 5, 6].includes(row.trade_kind) ? row.shop_goods_price + row.enterprise_freight_price : 0) |
                  unitPrice('￥')
                }}
              </span>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="图片" width="60" class-name="goods-cover-wrapper" fixed="left">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.image || row.goods_image" style="width: 300px;" alt />
              <img slot="reference" :src="row.image || row.goods_image" class="goods-cover" alt />
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" prop="goods_name" show-overflow-tooltip width="200" fixed="left">
          <template slot-scope="{row}">
            {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
          </template>
        </el-table-column>


        <el-table-column label="商品单价" width="100">
          <template slot-scope="{row}">

            <template>
              <div v-if="row.goods_type === 'NORMAL'">
                <span>{{ row.shop_price | unitPrice('￥') }}</span>
              </div>
              <div v-else>
                {{ row.revise_exchange_money | unitPrice('￥') }} +
                {{ row.revise_exchange_point }}积分
              </div>
            </template>

          </template>
        </el-table-column>
        <el-table-column label="商品数量" prop="goods_num" width="80" />

        <el-table-column label="售后状态" width="80">
          <template slot-scope="{row}">{{ MixinGetOrderStatus(row.service_status) }}</template>
        </el-table-column>
        <el-table-column label="物流状态" width="80">
          <template slot-scope="{row}">
            <span v-if="row.logistics_status_text">{{ row.logistics_status_text }}</span>
            <span v-else>{{ row.state === 0 ? '未发货' : (row.state === 2 ? '已发货' : '已收货') }}</span>
          </template>
        </el-table-column>

        <el-table-column label="物流单号" width="300" show-overflow-tooltip>
          <template slot-scope="{row}">
            <template v-if="row.delivery">{{ row.delivery.logiName }}: {{ row.delivery.deliveryNo }}</template>
          </template>
        </el-table-column>
        <el-table-column label="收货人姓名" width="100" prop="consignee_name" />
        <el-table-column label="收货人手机号" width="120" prop="consignee_mobile" />
        <el-table-column label="收货地址" width="300" show-overflow-tooltip align="center">
          <template slot-scope="{row}">
            {{ row.consignee_province + row.consignee_city + row.consignee_county + row.consignee_town +
              row.consignee_address
            }}
          </template>
        </el-table-column>
        <el-table-column label="供货方" width="80">
          <template slot-scope="{row}">
            <span>{{orderSourceMap[row.order_source]}}</span>
            </template>
        </el-table-column>

        <el-table-column label="订单状态" width="100">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">
              {{ row.trade_status_text }}
              <div v-if="row.hangup_status === 1" style="color: red;">(店铺挂起)</div>
              <div v-if="row.hangup_status === 2" style="color: red;">(企业挂起)</div>
            </template>

          </template>
        </el-table-column>
        <el-table-column label="订单类型" prop="trade_type" width="110">
          <template>
            普通商品订单
          </template>
        </el-table-column>
        <el-table-column label="下单时间" width="160">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>

        <el-table-column label="下单账号" prop="mobile" width="150" class-name="font-family-tttgb">
          <template slot-scope="{row}">
            <div v-if="row.mobile && row.login_account">
              <p>{{ row.mobile }}</p>
              {{ row.login_account }}
            </div>
            <div v-else>
              {{ row.mobile }}
              {{ row.login_account }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="下单备注信息" width="160">
          <template slot-scope="{row}">
            <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
              <div class="remark-box">
                {{ row.remark }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="300" fixed="right">
          <template slot-scope="{row}">
            <div style="display: flex;justify-content: center">
              <el-button type="primary" size="small" @click="handleOperateOrder(row)">查看详情</el-button>
              <el-button v-if="row.showButton && row.trade_status == 'PAID_OFF'" type="primary" size="small"
                @click="handleAddress(row)">修改地址</el-button>
              <el-button v-if="row.showButton && row.trade_status == 'PAID_OFF'" type="danger" size="small"
                @click="cancelTradeHandle(row)">取消订单</el-button>
            </div>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>
    <!--修改地址-->
    <el-dialog-x-dialog title="修改地址" :visible.sync="editAddressVisible">
      <el-form :model="txInfo" ref="txInfo" :rules="txRules" label-width="135px">
        <el-form-item label="收货人姓名" prop="consignee_name">
          <el-input placeholder="请输入收货人姓名" :maxlength="50" v-model="txInfo.consignee_name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="consignee_mobile">
          <el-input placeholder="请输入手机号" :maxlength="11" v-model="txInfo.consignee_mobile"></el-input>
        </el-form-item>
        <el-form-item label="收货地区" prop="province_id">
          <el-select @change="changeProvince('province')" size="small" class="choose-machine center"
            v-model="txInfo.province_id" placeholder="省/直辖市" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="( item, index ) in this.provinceList " :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince('city')" size="small" class="choose-machine center"
            v-model="txInfo.city_id" placeholder="城市/地区" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="( item, index ) in this.cityList " :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince('county')" size="small" class="choose-machine center"
            v-model="txInfo.county_id" placeholder="县/区" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="( item, index ) in this.countyList " :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select size="small" class="choose-machine center" v-model="txInfo.town_id" placeholder="乡镇/街道"
            style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="( item, index ) in this.townList " :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" prop="consignee_address">
          <el-input placeholder="请输入详细地址" :maxlength="100" v-model="txInfo.consignee_address"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="editAddressVisible = false" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="accountSubmit">确 定</el-button>
        </div>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import { RegExp } from "@/../ui-utils";
import { mapGetters } from "vuex";
import * as API_order from "@/api/order";
import { batchCancelOrder } from '@/api/importOrder';
import { handleDownload, mergeSkuList, mergesHandleDownload } from "@/utils";
import { Foundation } from "@/../ui-utils";
import { CategoryPicker } from "@/components";
import UploadExcelComponent from "@/components/UploadExcel";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import * as API_Goods from '@/api/goods';
export default {
  name: "orderList",
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker,
    UploadExcelComponent
  },
  computed: {
    ...mapGetters(["shopInfo"]),
    timeDefault () {
      const date = new Date();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() - 1)
      );
    }
  },
  data () {
    return {
      orderSourceMap: {
        0: '平台',
        1: '京东',
        3: '自有',
        4: '蛋糕叔叔',
        5: '企业自有供应商'
        // 新增其他来源时，在这里添加
      },
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      txInfo: {//修改地址表单内容
        def_addr: 1,
        name: '',
        mobile: '',
        consignee_address: '',
        province_id: "",
        city_id: "",
        county_id: "",
        town_id: ""
      },
      txRules: {//修改地址表单验证
        name: [
          { required: true, message: '请输入收货人姓名', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error("手机号格式有误，请重新输入"));
              } else {
                callback();
              }
            }, required: true, trigger: 'change'
          }],
        province_id: [
          { required: true, message: '请选择收货地区', trigger: 'change' }],
        consignee_address: [
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ],
      },
      editAddressVisible: false,//修改地址弹窗
      getOrderNum: { confirm_num: 0, wait_ship_num: 0 },//一件代发商城订单数量
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      // 列表选中数据
      tempList: [],
      activeName: null,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        trade_type: 1,
        order_status: "ALL",
        ...this.$route.query
      },
      /** 列表分页数据 */
      pageData: {},
      /** 高级搜索数据 */
      advancedForm: {
        shop_member_unit_id: "",
        shop_member_unit_id_two: "",
        order_time_range: [],
        logistics_status: undefined
      },
      templateHedaer: [
        "商品名称以及规格",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单号",
        "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "买家账号"
      ],
      templateKey: [
        "name",
        "sku_sn",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "sn",
        "order_status_text",
        "logi_name",
        //"ship_status_text",
        "ship_no",
        "member_name"
      ],


      search_type: "order_sn",
      obj: {},
      ship_no: "",

      addOrderLoading: false,
      pollTime: null,
      progress: '0%',
      tableData: [],
      merges: [],//表格导出合并
    };
  },
  mounted () {
    this.pollImportResult('init')
    if (sessionStorage.getItem('orderListActiveName') === '2') {
      this.activeName = sessionStorage.getItem('orderListActiveName')
    } else {
      this.activeName = '1'
    }

    delete this.params.market_enable;
    let { type } = this.$route.query;
    this.params = {
      ...this.params,
      ...this.$route.query
    };
    API_Goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
    });
    this.params["shop_type"] = 1;

    const arr = [];

    switch (type) {
      case "1":
        this.params.order_status = "PAID_OFF";
        break;
      case "3":
        this.params.order_status = "ALL";
        arr.push(this.timeDefault);
        arr.push(this.timeDefault);
        // 初始化搜索，默认为前一天
        this.advancedForm.order_time_range = arr;
        let now_date = new Date(); //获取Date对象
        now_date.setHours(0); //设置小时
        now_date.setMinutes(0); //设置分钟
        now_date.setSeconds(0); //设置秒
        now_date.setMilliseconds(0); //设置毫妙
        let timestamp = now_date.getTime() / 1000; //获取毫秒时间戳
        this.params = {
          ...this.params,
          start_time: timestamp - 86400,
          end_time: timestamp - 1
        };
        break;
      case "4":
        this.params.order_status = "COMPLETE";
        arr.push(this.timeDefault);
        arr.push(this.timeDefault);
        // 初始化搜索，默认为前一天
        this.advancedForm.order_time_range = arr;
        let now_date1 = new Date(); //获取Date对象
        now_date1.setHours(0); //设置小时
        now_date1.setMinutes(0); //设置分钟
        now_date1.setSeconds(0); //设置秒
        now_date1.setMilliseconds(0); //设置毫妙
        let timestamp1 = now_date1.getTime() / 1000; //获取毫秒时间戳
        this.params = {
          ...this.params,
          start_time: timestamp1 - 86400,
          end_time: timestamp1 - 1
        };
        break;
      default:
        break;
    }
    this.GET_OrderList(this.activeName);
  },
  beforeRouteUpdate (to, from, next) {
    delete this.params.market_enable;
    this.params = {
      ...this.params,
      ...this.$route.query
    };
    this.GET_OrderList();
    next();
  },
  destroyed () {
    sessionStorage.removeItem('orderListActiveName')
  },
  methods: {
    templateOrdernum (label) {
      if (label == "待付款") {
        return `(${this.getOrderNum.confirm_num})`
      } else if (label == "待发货") {
        return `(${this.getOrderNum.wait_ship_num})`
      } else {
        return ``
      }
    },
    orderListSelectionChange (list) {
      this.tempList = list;
    },
    /** 批量取消 */
    handleDeleteGoods () {
      let arr = [];
      if (this.tempList.length <= 0) {
        this.$message.error("请勾选订单");
        return;
      }
      arr = this.tempList.map(item => item.trade_sn);
      this.$confirm("确认批量取消订单吗, 如果存在无法取消订单，系统自动过滤无法取消订单数据", "提示", {
        type: "warning",
      }).then(() => {
        let newArr = [...new Set(arr)];
        batchCancelOrder({ trade_sn_list: newArr.toString() }).then((res) => {
          if (res.success==false) {//有取消失败的订单
            //过滤失败的订单数量 
            this.$message.success(`批量取消成功！有${res.fails}条数据无法取消已为您过滤掉`);
          } else {
            this.$message.success("批量取消成功！");
          }
          this.GET_OrderList();
        }).catch(() => {
          this.$message.error("批量取消失败！");
        });
      });
    },
    orderSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex >= 3 && columnIndex <= 9 || column.label == '供货方') {
        return {
          rowspan: 1,
          colspan: 1
        };
      } else {
        return row.span;
      }
    },
    // 标签切换
    handleClick (tab) {
      this.tableData = []
      this.advancedForm = {
        shop_member_unit_id: "",
        shop_member_unit_id_two: "",
        order_time_range: []
      }
      this.params = {
        page_no: 1,
        page_size: 20,
        order_status: "ALL",
        ...this.$route.query
      };
      if (this.activeName === "1") {
        this.params["shop_type"] = 2;
      }

      this.GET_OrderList(tab.name);
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_no = 1;
      this.params.page_size = size;
      this.GET_OrderList(this.activeName);
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_OrderList(this.activeName);
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
    console.log(this.params.order_status);
    
      if (this.params.order_status !== "ALL") this.advancedForm.logistics_status = undefined
      // 初始化搜索条件
      delete this.params.order_sn;
      delete this.params.buyer_name;
      delete this.params.goods_name;
      delete this.params.ship_no;
      delete this.params.ship_mobile;
      delete this.params.card_code;
      delete this.params.card_number;
      delete this.params.shop_name;

      this.params.booking_shipment = 0;

      this.obj = {};
      if (this.search_type) {
        this.obj[this.search_type] = this.advancedForm.keywords;
      }


      this.params = {
        ...this.params,
        ...this.advancedForm,
        ...this.obj
      };

      // delete this.params.order_status;
      delete this.params.start_time;
      delete this.params.end_time;
      delete this.params.deliverStartTime;
      delete this.params.deliverEndTime;
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.keywords;
      delete this.params.order_time_range;
      this.params.page_no = 1;

      this.GET_OrderList("1");

    },

    /** 查看、操作订单 */
    handleOperateOrder (item) {
      let route = this.$route.path.split("/")[2];

      this.$router.push({
        path: `/order/${route}/detail/${item.trade_sn}?type=${this.activeName}`
      });

    },
    //跳转导出订单记录
    exportOrderRecords () {
      let route = this.$route.path.split("/")[2];
      this.$router.push({
        path: `/order/${route}/exportOrderRecords`
      });
    },
    /**修改地址 */
    handleAddress (row) {
      row.province_id = row.consignee_province_id
      row.city_id = row.consignee_city_id
      row.county_id = row.consignee_county_id
      row.town_id = row.consignee_town_id
      this.txInfo = {
        ...row
      }
      console.log(this.txInfo)
      this.getAreaHandle("province");
      if (this.txInfo.province_id) {
        this.getAreaHandle("city", { id: this.txInfo.province_id });
      }
      if (this.txInfo.city_id) {
        this.getAreaHandle("county", { id: this.txInfo.city_id });
      }
      if (this.txInfo.county_id) {
        this.getAreaHandle("town", { id: this.txInfo.county_id })
      }
      this.editAddressVisible = true
    },
    /**提交 */
    accountSubmit () {
      this.$refs.txInfo.validate(valid => {
        let datas = {
          ...this.txInfo
        }
        if (valid) {
          if (datas.province_id !== "") {
            const findProvince = this.provinceList.find(item => item.id === datas.province_id)
            datas.province = findProvince.name;
            datas.jd_province_id = datas.province_id;
            datas.jd_province = datas.province;
            if (datas.city_id !== "") {
              const findCity = this.cityList.find(item => item.id === datas.city_id)
              datas.city = findCity.name;
              datas.jd_city_id = datas.city_id;
              datas.jd_city = datas.city;
            }
            if (datas.county_id !== "") {
              const findCointy = this.countyList.find(item => item.id === datas.county_id)
              datas.county = findCointy.name;
              datas.jd_county_id = datas.county_id;
              datas.jd_county = datas.county;
            }
            if (datas.town_id !== "") {
              const findTown = this.townList.find(item => item.id === datas.town_id)
              datas.town = findTown.name;
              datas.jd_town_id = datas.town_id;
              datas.jd_town = datas.town;
            }
          }
          API_order.updateTradeConsignee(datas).then((response) => {
            this.$message.success('收货地址修改成功！');
            this.editAddressVisible = false;
            this.GET_OrderList();
          })
        }
      });
    },

    /**选择地址change事件 */
    changeProvince (type) {
      if (type == "province") {
        this.txInfo.city_id = "";
        this.txInfo.county_id = "";
        this.txInfo.town_id = "";
        if (this.txInfo.province_id === "") {
          this.getAreaHandle("province");
        } else {
          this.getAreaHandle("city", { id: this.txInfo.province_id });
        }
      } else if (type == "city") {
        this.txInfo.county_id = "";
        this.txInfo.town_id = "";
        if (this.txInfo.province_id === "") return;
        if (this.txInfo.city_id !== "") this.getAreaHandle("county", { id: this.txInfo.city_id });
      } else if (type == "county") {
        this.txInfo.town_id = "";
        if (this.txInfo.city_id === "") return;
        if (this.txInfo.county_id !== "") this.getAreaHandle("town", { id: this.txInfo.county_id });
      }
    },
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === "province") {
        API_Goods.getJDProvince().then((res) => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_Goods.getJDCity(data.id).then((res) => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_Goods.getJDCounty(data.id).then((res) => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_Goods.getJDTown(data.id).then((res) => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },
    dataHandle (data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index],
        };
        returnData.push(data);
      });
      return returnData;
    },
    /** 取消订单 */
    cancelTradeHandle (row) {
      if (row.delivery && row.delivery.deliveryNo) {
        this.$message.error("存在已发货商品，无法取消订单");
        this.GET_OrderList();
        return
      }
      this.$confirm('确定要取消该订单吗?', '提示', {
        confirmButtonText: '确定取消',
        cancelButtonText: '暂不取消',
        type: 'warning'
      }).then(() => {
        API_order.cancelTrade({ trade_sn: row.trade_sn }).then(res => {
          if (!row.isCancelOrder) row.isCancelOrder = 1
          this.$message({
            type: 'success',
            message: '订单取消成功'
          });
          this.GET_OrderList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    pollImportResult (type) {
      if (this.pollTime) clearInterval(this.pollTime)
      this.pollTime = setInterval(() => {
        API_order.pollImportResult().then(res => {
          if (res.surplus === 0) {
            clearInterval(this.pollTime)
            this.addOrderLoading = false
            this.GET_OrderList();
          } else {
            this.progress = Math.ceil(((res.total - res.surplus) / res.total) * 100) + '%'
            this.addOrderLoading = true
          }
        })
      }, 3000)
      if (type === 'init') {
        API_order.pollImportResult().then(res => {
          if (res.surplus === 0) {
            clearInterval(this.pollTime)
            this.addOrderLoading = false
          } else {
            this.progress = Math.ceil(((res.total - res.surplus) / res.total) * 100) + '%'
            this.addOrderLoading = true
          }
        })
      }
    },
    //站点订单列表导出方法
    submitImport () {
      this.merges = [];
      this.importLoading = true;
      let tHeaders = [];
      let filterVals = [];
      if (this.parentLogin === 1) {
        tHeaders = [
          "订单编号",
          "订单总额",
          "商品名称",
          "商品单价",
          "分销价",
          // "销售价",
          "会员价",
          "商品数量",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "下单时间",
          "下单账号",
          "收件人",
          "收件电话",
          "收货地址",
          "下单备注信息"
        ];
        filterVals = [
          "trade_sn",
          "total_self_owned_orders_price",
          "goods_name",
          "shop_price",
          "enterprise_price",
          // "shop_price",
          "original_price",
          "goods_num",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",//订单状态
          "create_time",
          "member_name",
          "consignee_name",
          "consignee_mobile",
          "address",
          "remark"
        ];
      } else {
        tHeaders = [
          "订单编号",
          "订单总额",
          "商品名称",
          "商品单价",
          "分销价",
          // "销售价",
          "商品数量",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "下单时间",
          "下单账号",
          "收件人",
          "收件电话",
          "收货地址",
          "下单备注信息"
        ];
        filterVals = [
          "trade_sn",
          "total_self_owned_orders_price",
          "goods_name",
          "shop_price",
          "enterprise_price",
          // "shop_price",
          "goods_num",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",//订单状态
          "create_time",
          "member_name",
          "consignee_name",
          "consignee_mobile",
          "address",
          "remark"
        ];
      }

      let params = { ...this.params };

      params.page_no = 0;
      params.page_size = 0;
      API_order.getOrderList(params).then(res => {
        let data = mergeSkuList(res.data, 1);
        data.forEach(item => {
          item.total_self_owned_orders_price = [1, 5, 6].includes(item.trade_kind) ? item.shop_goods_price + item.shop_freight_price : 0
          item.goods_name = item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
          item.service_status = this.MixinGetOrderStatus(item.service_status);
          item.state = item.logistics_status_text ? item.logistics_status_text :
            item.state === 0
              ? "未发货"
              : item.state === 2
                ? "已发货"
                : "已收货";
          item.order_source =this.orderSourceMap[item.order_source];
          item.create_time = Foundation.unixToDate(item.create_time);
          item.deliveryNo = item.delivery &&(item.delivery.logiName+' '+item.delivery.deliveryNo) ;
          item["address"] =
            item.consignee_province +
            item.consignee_city +
            item.consignee_county +
            item.consignee_town +
            item.consignee_address;
          item.platform_price = item.order_source === '自有' ? '---' : item.platform_price;
        });
        //需要合并的单元格
        data.forEach((m, index) => {
          let uum = 0;
          let columnCount = 18;
          this.parentLogin === 1 ? uum = 0 : uum = 1 //是否显示会员价
          if (this.isSpecificEnterprise) {
            columnCount += 1;
          }
          if (m.span.rowspan > 1) {
            for (let i = 0; i < columnCount - uum; i++) {
              if (i < 2 || i > 10 - uum) {
                this.merges.push(
                  { s: { r: index + 1, c: i }, e: { r: index + m.span.rowspan, c: i } }
                )
              }
            }
          }
        })
        mergesHandleDownload(data, tHeaders, filterVals, "订单列表", true, this.merges);
        // handleDownload(data, tHeaders, filterVals, "订单列表");
        this.importLoading = false;
      });
    },
    async GET_OrderList (tab = this.activeName) {
      // 查询订单数量 
      this.getOrderNum = await API_order.getOrderNum()
      this.loading = true;
      // 企业平台商品，站点

      delete this.params.keywords;
      delete this.params.shop_member_unit_id_two;
      delete this.params.shop_member_unit_id;
      delete this.params.booking_shipment;
      delete this.params.type;
      API_order.getOrderList(this.params).then(res => {
        this.loading = false
        this.pageData = res;
        this.tableData = mergeSkuList(res.data, 1);
      });

    },
  }
};
function getExportMetadata (type) {
  let orderFunc, filterVals, tHeaders;
  switch (type) {
    case 1:
      orderFunc = "getSellerOrderList";
      filterVals = [
        "name",
        "sku_sn",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "order_sn",
        "order_status_text",
        "logi_name",
        //"ship_status_text",
        "ship_no",
        "member_name"
      ];
      tHeaders = [
        "商品名称以及规格",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单号",
        "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "买家账号"
      ];
      break;
    case 2:
      filterVals = [
        "goods_name",
        "goods_sn",
        "ship_num",
        // "ship_addr",
        // "ship_name",
        // "ship_mobile",
        "order_sn",
        // "order_status_text",
        "logi_name",
        //"ship_status_text",
        "delivery_no",
        "remark"
      ];
      tHeaders = [
        "商品名称",
        "商品编号",
        "数量",
        // "详细地址",
        // "姓名",
        // "电话",
        "订单号",
        // "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "错误原因"
      ];
  }

  return {
    orderFunc,
    filterVals,
    tHeaders
  };
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1545px) {
  .restyle1545 {
    margin-top: 12px;
  }
}

.restyle1545 {
  width: 410px;
}

/deep/ {
  .el-tabs__header {
    margin-bottom: 0;
  }

  .goods-cover-wrapper .cell {
    padding-left: 0 !important;
  }
}

.conceal {
  white-space: nowrap; //不换行
  overflow: hidden; //超出则隐藏
}

/deep/.import-order-dialog {
  .el-dialog {
    min-width: 1024px;
    margin-top: 10vh !important;
  }

  .el-dialog__body {
    max-height: 65vh;
    overflow-y: scroll;
  }
}

/deep/.el-loading-spinner {
  .el-icon-loading,
  .el-loading-text {
    color: white;
    font-size: 16px;
  }
}

.remark-box {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.dialog-footer {
  text-align: center;
  margin: 20px 0;
}
</style>
