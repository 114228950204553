
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { getAddressById, getAddress } from "@/components/x-address-select/api";

const loaded: { [key: number]: any } = {};

const handleAdder = (adderss) => {
  if (!adderss || !adderss.length) return [];
  return adderss.map(item => {
    const children = handleAdder(item.c)
    return {
      label: item.l,
      value: item.i,
      leaf: !children.length,
      children: children.length ? children : undefined
    };
  });
}
const findNode = (id, address) => {
  for (let i = 0; i < address.length; i++) {
    const item = address[i];
    if (item.value === id) {
      return item
    } else {
      const children = item.children
      if (children && children.length) {
        const res = findNode(id, children)
        if (res) {
          return res
        }
      }
    }
  }
}
@Component({
  name: "x-address-select"
})
export default class XAddressSelect extends Vue {
  @PropSync('value', {
    default: () => [],
    required: true,
  }) propValue!: any[];

  @Prop({ default: () => '100%' }) width!: string;
  @Prop({ default: false }) clearable!: boolean

  props: any = null!;
  options: Array<any> = [];

  isFirst = true;

  created() {
    const address = handleAdder(this.$store.getters.address)
    console.log('address', address);
    this.options = address
    this.props = {
      // lazy: true,
      expandTrigger: 'hover',
      checkStrictly: true,
      // lazyLoad: (node, resolve) => {
      //   let id = 0;

      //   if (!node.root) {
      //     id = node.data.value;

      //     if (node.data.leaf) {
      //       return resolve([]);
      //     }
      //   }

      //   if (loaded[id]) {
      //     return setTimeout(() => {
      //       resolve(loaded[id]);
      //     }, 500);
      //   }

      //   getAddressById(id).then(resp => {

      //     const results = resp.map(item => {
      //       return {
      //         label: item.local_name,
      //         value: item.id,
      //         leaf: !!item.is_leaf,
      //       };
      //     });

      //     resolve(results);
      //   });
      // },
    };

  }

  mounted() {
  }

  @Watch('$store.getters.address')
  valChange(e) {
    if (!Array.isArray(e) || !e.length) return
    this.options = handleAdder(e)
  }

  change(c) {
    console.log(222, c);
    if (!Array.isArray(c) || !c.length) return
    if (c.length === 4) return this.$emit('change', c), (this.$refs.xAddressSelect as any).dropDownVisible = false
    const node = findNode(c[c.length - 1], this.options)
    if (!node || node.leaf) return this.$emit('change', c), (this.$refs.xAddressSelect as any).dropDownVisible = false
    const arr: number[] = []
    const getIds = (node) => {
      if (node.leaf) {
        arr.push(node.value)
      } else {
        arr.push(node.value)
        getIds(node.children[0])
      }
    }
    getIds(node)
    this.$emit('change', [...c, ...arr.slice(1)])
      ; (this.$refs.xAddressSelect as any).dropDownVisible = false
  }
  getCheckedNodes(e) {
    const node = (this.$refs.xAddressSelect as any).getCheckedNodes()[0]
    if (node) {
      const { label, leaf, value } = node.data
      return { label, leaf, value }
    }
  }
  visibleChange() {
    console.log('visibleChange');
    this.isFirst = false
  }
  async expandChange(e) {
    if (this.isFirst) return
    let simulateHover = (element) => {
      if (element) {
        const mouseEnterEvent = new Event('mouseenter');
        element.dispatchEvent(mouseEnterEvent);
      }
    }

    let removeHover = (element) => {
      if (element) {
        const mouseLeaveEvent = new Event('mouseleave');
        element.dispatchEvent(mouseLeaveEvent);
      }
    }

    const panel = (this.$refs.xAddressSelect as any).panel
    const menus = panel.menus
    const activePath = panel.activePath
    const len = e.length
    const currentCol = menus[len]
    const currentNode = currentCol[0]
    const firstId = currentNode.value
    this.$emit('change', [...e, firstId])

    setTimeout(() => {
      const menusRefs = (this.$refs.xAddressSelect as any).$refs.panel.$refs.menu
      const currentMenuRef = menusRefs[len]
      const currentEl = currentMenuRef.$el.querySelector('.el-cascader-node.is-active')
      simulateHover(currentEl)
      setTimeout(() => {
        removeHover(currentEl)
      }, 10);
    }, 50);
  }
}
