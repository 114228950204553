import request from "@/utils/request";
import { api } from "@/../ui-domain";
function getAddress (depth = 4) {
  return request({
    url: `${api.base}/regions/depth/zip/${depth}`,
  });
}
  const address = {
  namespaced: true,
  state: {
    address: [], 
  },
  mutations: {
    SET_ADDRESS: (state, address) => {
      state.address = address;
    }
  },
  actions: {
    getAddress ({ commit, state }) { 
      if (state.address.length) return;
      getAddress().then(res => {
        commit('SET_ADDRESS', res); 
      })
    }
  }
}

export default address;